.bg-full {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}
.error-span {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.input-group-text.disabled {
  background: #e9ecef !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rounded-nav-link {
  border-radius: 10px;
  margin: 5px;
  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.075) !important;
}
.rounded-nav-link.active {
  background: linear-gradient(0deg, #93291e 0, #ed213a 100%) !important;
}
.rounded-nav-link.active > a {
  color: #e9ecef !important;
}
.normal-wrap {
  white-space: normal !important;
}
.break-me {
  margin: 0 10px;
  font-size: 25px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 47px;
  height: 47px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 47px;
  height: 47px;
  margin: 6px;
  border: 6px solid #293f87;
  border-radius: 50%;
  animation: lds-ring 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #293f87 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.example-enter {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}
.example-enter.example-enter-active {
  opacity: 1;
}
.example-leave {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}
.example-leave.example-leave-active {
  opacity: 0;
}
.form-select {
  padding: 0.25rem 0.75rem;
  height: auto;
}
.form-select > .css-vj8t7z {
  border: none;
  background-color: transparent;
}
.has-danger[type="date"]:after {
  margin-right: 40px;
}
.has-danger.css-10nd86i:after {
  margin-right: 40px;
}
.sk-spinner.d-flex > div {
  height: 15px;
  margin-top: 5px;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 30px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1200ms infinite linear;
  -moz-animation: spinner 1200ms infinite linear;
  -ms-animation: spinner 1200ms infinite linear;
  -o-animation: spinner 1200ms infinite linear;
  animation: spinner 1200ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: #60a7fb 1.5em 0 0 0, #467ab7 1.1em 1.1em 0 0,
    #60a7fb 0 1.5em 0 0, #467ab7 -1.1em 1.1em 0 0, #60a7fb -1.5em 0 0 0,
    #467ab7 -1.1em -1.1em 0 0, #60a7fb 0 -1.5em 0 0, #467ab7 1.1em -1.1em 0 0;
  box-shadow: #60a7fb 1.5em 0 0 0, #467ab7 1.1em 1.1em 0 0, #60a7fb 0 1.5em 0 0,
    #467ab7 -1.1em 1.1em 0 0, #60a7fb -1.5em 0 0 0, #467ab7 -1.1em -1.1em 0 0,
    #60a7fb 0 -1.5em 0 0, #467ab7 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.sk-circle {
  width: 120px;
  height: 120px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  margin-bottom: 40px;
}
.loader-text {
  margin-left: auto;
  position: relative;
  color: white;
  font-weight: bolder;
  margin-right: auto;
  text-align: center;
}
.loader-backdrop {
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #20487496;
  display: block;
  z-index: 992;
  overflow: auto;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: white;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.text-black{
  color:black !important
}

.text-white{
  color:white !important
}
.text-success{
  color:green !important
}
.text-danger{
  color:red !important
}

.font-regular{
  font-weight: normal !important
}

/* 
.css-15k3avv{
  z-index: 9999 !important;
} */